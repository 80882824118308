import React, { useCallback, useEffect, useState } from 'react';
import BottomMenu from '../bottomMenu';
import { useSelector } from "react-redux";
import { LiveKitTile } from '../livekitIntegration/livekitTile.tsx';
import { RootState } from '../../state/store.tsx';

const MeetingMobile = (props: any) => {
    const { theme } = props;
  const livekitTracks = useSelector((state) => state.livekit.tracks);
  const audioTracks = useSelector((state) => state.livekit.audioTracks);
  const livekitslice = useSelector((state) => state.livekit);
  const trackPosition = useSelector((state) => state.livekit.trackPosition);
  const videoMuteIdsList = useSelector(
    (state) => state.conference.videoMuteIds
  );
 const currentActiveUserId = useSelector(
   (state: RootState) => state.conference.currentActiveUserId
 );
  const [gridTiels, setgridTiels] = useState([]);
  
    const getTiles = useCallback(() => {
      const tiles:any[] = [];
      const customTrackPositions = {};
      trackPosition.forEach((track_position, index) => {
        customTrackPositions[track_position.trackId] = track_position;
      });

    
       
      livekitTracks.forEach((track, index) => {
        // forloop for audio tracks to find the track with same participant id
        let audioTrack = audioTracks.find((audio) => {
          return audio.getParticipantId() == track.getParticipantId();
        });

        let localaudioTrack = livekitslice.localAudioTracks.find((audio) => {
          return audio.getParticipantId() == track.getParticipantId();
        });

        let trackId = track.sid;

        // let userType = window.room?.getParticipantById(track.getParticipantId())
        //   ._properties.user_type;
        let userType = customTrackPositions[trackId]?.trackType;

        if (userType == "P" || userType == "S") {
          tiles.push(
            <div
              className={`${
                currentActiveUserId == trackId &&
                props.mobileTileView &&
                "grid-item-first-child"
              } grid-item`}
            >
              <LiveKitTile
                trackUniqueId={trackId}
                draggable={false}
                userId={track.getParticipantId()}
                tileType="remote"
                type="tier1"
                track={track}
                audio={audioTrack}
                user={
                  window.room?.getParticipantById(track.getParticipantId())
                    ._properties
                }
                videoMuted={videoMuteIdsList.includes(track.getParticipantId())}
                participant={window.room?.getParticipantById(
                  track.getParticipantId()
                )}
              />
            </div>
          );
        } else if (userType == "V" && track.customProperty?.is_local) {
          // check if track is instance of LocalVideoTrack
          try {
            track.setTrackMuted(true);
            localStorage.setObject("videoMuted", true);

            if (localaudioTrack) {
              localaudioTrack.setTrackMuted(true);
              localStorage.setObject("audioMuted", true);
            }
          } catch (e) {
            console.log(e, "error in setting track muted");
          }
        }
      });


       
      return tiles;
    }, [audioTracks, currentActiveUserId, livekitTracks, livekitslice.localAudioTracks, props.mobileTileView, trackPosition, videoMuteIdsList]);

    
   useEffect(() => {
     setgridTiels(getTiles());
   }, [

     getTiles,
   ]);


  

    return (
      <div>
        <div className="grid-container">
          {gridTiels}
     
        </div>
        <BottomMenu theme={theme} />
      </div>
    );
};

export default MeetingMobile;